@tailwind base;
@tailwind components;
@tailwind utilities;

/* *::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
} */
.selectWithIcon {
  padding-left: 40px !important;
}
.selectWithIcon ::placeholder {
  color: #667085;
}
.authForm input {
  border-color: #cccfd6ec !important;
}
.chakra-input__left-addon {
  border-color: #cccfd6ec !important;
}
.businessRegForm input,
select,
textarea {
  border-color: #cccfd6ec !important;
}
@keyframes ImgBounce {
  0% {
    transform: rotateZ(360deg);
  }
}
.loadingIcon svg {
  -webkit-animation: ImgBounce 1s infinite;
  animation: ImgBounce 1s infinite;
  width: 20%;
  height: 20%;
}

@media (min-width: 1536px) and (max-width: 1676px) {
  .dashboard-stats-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.react-datepicker-popper {
  z-index: 99999;
}

.react-datepicker-popper {
  position: relative !important;
}

#react-select-2-placeholder {
  display: inline-flex;
}
